export default {
  methods: {
    getLocation() {
      let position = this.$store.state.currentPosition;
      if (!!position) {
        this.form.province = position.ad_info.province;
        this.form.city = position.ad_info.city;
        this.form.district = position.ad_info.district;
        this.form.address = position.address;
        this.form.addressDetail = position.address;
      }
    },
    translateAddress(latlng) {
      this.$jsonp(
        "https://apis.map.qq.com/ws/geocoder/v1/?key=MLMBZ-JXJCW-TXVR3-OF7AG-ZD5CF-ONFBC&output=jsonp&location=" +
          [latlng.lat, latlng.lng].join(",")
      ).then((res) => {
        this.form.province = res.result.ad_info.province;
        this.form.city = res.result.ad_info.city;
        this.form.district = res.result.ad_info.district;
        // 为了统一，同时传address 和 addressDetail
        this.form.address = res.result.address + res.result.formatted_addresses.recommend;
        this.form.addressDetail = res.result.address + res.result.formatted_addresses.recommend;
        console.log(res)
      });
    },
  },
  
};
