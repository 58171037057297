<template>
  <div class="form">
    <div>
      <div class="weui-cells__title">行程记录</div>
      <div class="weui-cells">
        <adate
          text="出行时间"
          :model.sync="records.goTime"
          placeholder="请选择"
          :required="true"
        ></adate>
         <ainput
            type="text"
            text="出行原因"
            :model.sync="records.tripReason"
            value=""
            placeholder="请输入"
            :required="computeRequire"
         ></ainput>
      </div>

      <common-position
        @change="choosePostion"
        :defalutForm="position"
      ></common-position>

      <div class="weui-cells">
        <apicker
          :options="transport"
          text="交通工具"
          :model.sync="records.tripWayId"
          placeholder="请选择"
          :required="true"
        ></apicker>
        <ainput
          type="text"
          text="班次"
          :model.sync="records.tripNo"
          placeholder="请输入"
          :required="records.tripWayId != 3"
        ></ainput>
      </div>

      <div class="weui-cells">
        <ainput
          type="text"
          text="目的地负责人"
          :model.sync="records.aimAddressManager"
          value=""
          placeholder="请输入"
          :required="computeRequire"
        ></ainput>
        <ainput
          type="text"
          text="紧急联系人电话"
          :model.sync="records.emergencyMobile"
          value=""
          placeholder="请输入"
          :required="computeRequire"
        ></ainput>
      </div>
    </div>
    <div class="btn-area">
      <button class="weui-btn weui-btn_primary" @click="submit">确认</button>
    </div>
    <div class="weui-cells__title" v-if="!isEdit">最近添加记录</div>
    <section class="hisContent" v-if="!isEdit">
      <el-timeline>
        <el-timeline-item
          v-for="(item, index) in history"
          :key="index"
          :timestamp="item.goTime.substr(0, 10)"
        >
          <div class="tit">
            <span
              >目的地：{{
                (item.abroadName ? item.abroadName : "中国") +
                (item.province || "") +
                (item.city || "") +
                (item.district || "") +
                item.aimAddress
              }}</span
            >
            <p>
              交通工具：{{
                item.tripWayId == 2
                  ? "飞机"
                  : item.tripWayId == 1
                  ? "火车"
                  : "其他"
              }}
            </p>
            <p>班次：{{ item.tripNo }}</p>
            <p>出行原因：{{ item.tripReason }}</p>
          </div>
        </el-timeline-item>
      </el-timeline>
    </section>
    <p class="viewAllBtn" @click="goHistory" v-if="!isEdit">点击查看全部</p>
  </div>
</template>

<script>
import ainput from "@/components/form/input";
import apicker from "@/components/form/picker";
import adate from "@/components/form/datepicker";
import aradio from "@/components/form/radio";
import location from "@/mixins/getLocation";
import time from "@/mixins/time";
import webview from "@/mixins/webview";
import commonPosition from "@/components/common/position";
import {
  TripList,
  TripSave,
  tripEdit,
  tripList,
  getAllcounrtyData,
  getCurrentNewTrip,
} from "@/api/api";
import {
  provinceAndCityData,
  regionData,
  provinceAndCityDataPlus,
  regionDataPlus,
  CodeToText,
  TextToCode,
} from "element-china-area-data";
export default {
  data() {
    return {
      name: "Jour",
      records: {
        mobile: this.$store.state.userInfo.mobile,
        goTime: this.nowTime(),
        tripReason: "",
        backTime: this.nowTime(),
        tripNo: "",
        tripWayId: "",
        aimAddress: "",
        inChina: 0,
        abroadName: "",
      },
      requireFlag: true,
      mapFlag: false,
      transport: [
        {
          label: "火车",
          value: 1,
        },
        {
          label: "飞机",
          value: 2,
        },
        {
          label: "其他",
          value: 3,
        },
      ],
      currentIndex: 0,
      isEdit: false,
      history: [],
      position: {},
      form: {
        inChina: 0,
      },
      radios: [
        {
          label: "境内",
          value: 0,
        },
        {
          label: "境外",
          value: 1,
        },
      ],
      countries: [],
      flag: false,
    };
  },
  created() {
    this.isWebView();
    //this.getList();
    this.getHistoryList();
    this.getCountries();

    let _this = this;
    let isEdit = Boolean(this.$route.query.isEdit);
    this.isEdit = isEdit;
    document.title = isEdit ? "编辑行程" : "行程登记";
    this.records = {
      mobile: this.$store.state.userInfo.mobile,
      goTime: this.nowTime(),
      tripReason: "",
      tripNo: "",
      tripWayId: "",
      aimAddress: "",
      inChina: 0,
      abroadName: "",
    };
    if (this.isEdit) {
      this.obj = JSON.parse(this.$route.query.data);
      this.position = {
        country: this.obj.abroadName ? this.obj.abroadName : "中国",
        province: this.obj.province,
        city: this.obj.city,
        district: this.obj.district,
        aimAddress: this.obj.aimAddress,
      };
      this.records = {
        mobile: this.$store.state.userInfo.mobile,
        goTime: this.obj.goTime,
        tripReason: this.obj.tripReason,
        tripNo: this.obj.tripNo,
        tripWayId: this.obj.tripWayId,
        aimAddress: this.obj.aimAddress,
        aimAddressManager: this.obj.aimAddressManager,
        emergencyMobile: this.obj.emergencyMobile,
        abroadName: this.obj.abroadName ? this.obj.abroadName : "中国",
        province: this.obj.province,
        city: this.obj.city,
        district: this.obj.district,
        aimAddress: this.obj.aimAddress,
        inChina: this.obj.inChina,
      };
    }
  },
  mixins: [location, time, webview],
  methods: {
    // 获取历史记录
    getHistoryList() {
      let mobile = this.$store.state.userInfo.mobile;
      tripList({ mobile: mobile }).then((res) => {
        this.history = res.data.data.slice(0, 3);
      });
    },
    // 跳转历史记录
    goHistory() {
      this.$router.push("/journey/history");
    },
    // 添加隔离记录
    goRecord() {
      this.$store.commit("setCache", { key: "journey", data: this.records });
      this.$router.push({ name: "Quarantine" });
    },
    // 删减记录
    mul_record() {
      this.records.pop();
    },
    // 提交
    submit() {
      let form = [this.records];
      let flag = true;
      let tip = "";
      form.forEach((item) => {
        if (!item.aimAddress) {
          tip = "目的地";
          flag = false;
        }
        if (!item.goTime) {
          tip = "出行时间";
          flag = false;
        }
        if (!item.tripReason ) {
          tip = "出行原因";
          flag = false;
        }
        if (!item.tripWayId) {
          tip = "交通工具";
          flag = false;
        }
        if (!item.aimAddressManager && this.computeRequire) {
          tip = "紧急联系人";
          flag = false;
        }
        if (!item.emergencyMobile && this.computeRequire) {
          tip = "紧急联系人";
          flag = false;
        }
        if (!item.tripNo && item.tripWayId != 3) {
          tip = "班次";
          flag = false;
        }
        if(item.inChina != 1 && (!item.province||!item.city||!item.district)){
          console.log(item.province,item.city,item.district,item.inChina)
          tip = "省市区";
          flag = false;
        }
        if(!item.country){
          tip="国家"
          flag = false;
        }
      });
      if (flag) {
        if (this.isEdit) {
          tripEdit(this.obj.id, this.records).then(async (res) => {
            this.$store.commit("clearCache", {});
            this.$weui.toast("提交成功", {
              duration: 1500,
              className: "custom-classname",
            });
            let mobile = this.$store.state.userInfo.mobile;
            let result = await getCurrentNewTrip({ mobile });
            this.$store.state.userInfo.beforeNowLatestTrip = result.data.data;
            if (window.__wxjs_environment === "miniprogram") {
              setTimeout(() => {
                wx.miniProgram.navigateBack();
              }, 2000);
            } else {
              this.refresh();
            }
          });
        } else {
          TripSave({ tripList: form }).then(async (res) => {
            this.$store.commit("clearCache", {});
            let mobile = this.$store.state.userInfo.mobile;
            let result = await getCurrentNewTrip({ mobile });
            this.$store.state.userInfo.beforeNowLatestTrip = result.data.data;
            this.$weui.toast("提交成功", {
              duration: 1500,
              className: "custom-classname",
            });
            if (window.__wxjs_environment === "miniprogram") {
              setTimeout(() => {
                wx.miniProgram.navigateBack();
              }, 2000);
            } else {
              this.refresh();
            }
          });
        }
      } else {
        this.$weui.toast(tip + "为必填项", {
          duration: 1500,
          className: "error",
          callback: () => {},
        });
      }
    },
    choosePostion(e) {
      this.records = { ...this.records, ...e };
      this.records.abroadName = e.country;
    },
    // 获取交通工具列表
    async getList() {
      let res = await TripList();
      let arr = res.data.data.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
      this.transport = arr;
    },
    refresh() {
      this.$router.go(-1);
    },
    async getCountries() {
      let res = await getAllcounrtyData();
      this.countries = res.data.data.map((item) => {
        return {
          label: item.name,
          value: item.name,
        };
      });
    },
  },
  computed: {
    computeRequire() {
      return (
        this.records.country !=
        (this.history[0] && this.history[0].abroadName
          ? this.history[0].abroadName
          : "中国")
      );
    },
  },
  components: {
    ainput,
    apicker,
    adate,
    aradio,
    commonPosition,
  },
  distroyed() {},
};
</script>

<style scoped>
.add {
  font-size: 0.14rem;
  color: #2f7dcd;
  text-align: center;
  margin-top: 0.1rem;
}
.position {
  color: #2f7dcd;
  margin-left: 0.1rem;
  width: 0.6rem;
  font-size: 0.12rem;
  background: #2f7dcd;
  color: #fff;
  padding: 3px 5px;
  text-align: center;
  border-radius: 5px;
  flex-shrink: 0;
}
.map {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.hisContent {
  padding: 0.1rem 0.25rem;
}

.weui-cells__title {
  color: #2f7dcd;
}
</style>